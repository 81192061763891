import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import * as Theme from "../Theme";
import TermsDialog from "./TermsDialog";

const DIALOG_TYPE = {
  TERMS: "TERMS",
};

class OrderHintBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      dialogType: DIALOG_TYPE.TERMS,
    };
  }
  render() {
    let { openDialog, dialogType } = this.state;
    const { note, editNote, toCheckHint } = this.props;

    return (
      <div style={{ border: "1px solid #ddd", backgroundColor: "#f0f0f0" }}>
        <div
          style={{
            padding: 10,
            textAlign: "center",
            borderBottom: "1px solid #ddd",
          }}
        >
          注意事項
        </div>
        <div style={{ padding: 15 }}>
          <Row>
            <Checkbox
              style={{ marginRight: 10 }}
              onChange={e => toCheckHint({ checkHint1: e.target.checked })}
            >
              <>
                <div style={{ fontWeight: "bold", display: "inline-block" }}>
                  我已閱讀並同意接受本網站所有服務條款。
                </div>
                <div>
                  為維護您的權益，下單前請務必詳讀本網站所有『條款聲明』，交易之始，即表示您同意上述須知事項。本網站保有審核、終止承攬與解釋條約之權利。
                </div>
              </>
            </Checkbox>
          </Row>
          <Row>
            <Ant.Button
              type="link"
              style={{ fontWeight: "bold" }}
              onClick={() =>
                this.setState({
                  openDialog: true,
                  dialogType: DIALOG_TYPE.TERMS,
                })
              }
            >
              ＋條款聲明
            </Ant.Button>
          </Row>

          <Row css={"margin-top: 20px"}>
            <div
              style={{
                fontWeight: "bold",
                color: "#3E3A39",
                fontSize: 16,
                marginRight: 20,
              }}
            >
              備註事項
            </div>
            <Ant.Input.TextArea
              style={{ flex: 1 }}
              value={note}
              placeholder="請輸入製作備註/加工備註/訂單備註。"
              onChange={e => editNote(e.target.value)}
            />
          </Row>
        </div>
        {openDialog &&
          (() => {
            if (dialogType == DIALOG_TYPE.TERMS) {
              return (
                <TermsDialog
                  closeDialog={() => this.setState({ openDialog: false })}
                />
              );
            }
          })()}
      </div>
    );
  }
}

const Row = styled.div`
  display: flex;

  ${props => props.css}
`;

const Checkbox = styled(Ant.Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Theme.colors.main};
    border-color: ${Theme.colors.main};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${Theme.colors.main};
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
`;

export default OrderHintBlock;
