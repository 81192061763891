import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Catalog from "../Contexts/CatalogContext";
import * as Ant from "antd";
import CartItem from "./CartItem";
import PdfDownloadButton from "./PdfDownloadButton";
import * as Theme from "../Theme";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget";
import * as Icon from "./Icon";

class CartDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async componentDidMount() {}

  async componentDidUpdate(prevProps) {}

  render() {
    let { viewOnly, cartData, goToNextStep } = this.props;

    if (cartData.items.length === 0) {
      return <div style={{ textAlign: "center" }}>您的購物車為空！</div>;
    }

    return (
      <div>
        {cartData.items.map((item, idx) => {
          return (
            <CartItem
              viewOnly={viewOnly}
              product={item.product}
              item={item}
              itemIndex={idx}
            />
          );
        })}

        <TotalDataRow>
          <div style={{ position: "absolute", left: 15, top: 15 }}>
            <Icon.Loyalty size={20} color="#999" />
          </div>
          <div style={{ marginRight: 5 }}>{`共`}</div>
          <div
            style={{ color: Theme.colors.main, marginRight: 5 }}
          >{`${cartData.items.length}`}</div>
          <div style={{ marginRight: 10 }}>{`個品項 /`}</div>
          <div style={{ marginRight: 10 }}>{`課程價格合計(含稅)`}</div>
          <div style={{ marginRight: 10, color: Theme.colors.main }}>
            ${`${cartData.calculations && cartData.calculations.amount}`}
          </div>
        </TotalDataRow>

        {goToNextStep && (
          <Footer>
            <Ant.Button
              style={{ flexGrow: 0 }}
              onClick={() => this.props.navActions.push("products")}
            >
              繼續購物
            </Ant.Button>
            <div style={{ flex: 1 }}></div>
            <Ant.Button
              type="primary"
              style={{
                flexGrow: 0,
                backgroundColor: `${Theme.colors.main}`,
                border: 0,
              }}
              onClick={goToNextStep}
            >
              下一步
            </Ant.Button>
          </Footer>
        )}
      </div>
    );
  }
}

const TotalDataRow = styled.div`
  margin-top: 20px;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin-bottom: 100px;
  }
`;

export default connect(null, ActionCreator)(CartDetail);
