import React from "react";
import styled from "styled-components";
import { QuotationFormPdfDownload } from "./QuotationFormPdfDownload";
import * as Theme from "../Theme";
import * as Cart from "../Contexts/CartContext";

class PdfDownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfReady: false,
    };
  }

  render() {
    const { order, productSpec } = this.props;
    const { pdfReady } = this.state;

    return (
      <>
        <Button
          onClick={() => {
            if (pdfReady) {
              Cart.Actions.markOrderHasDownloadQuotationForm(order.id, true);
            }
            this.setState({ pdfReady: true });
          }}
          color={Theme.colors.main}
        >
          {pdfReady ? (
            <QuotationFormPdfDownload
              order={order}
              productSpec={productSpec}
              style={{ color: "#ffffff", textDecoration: "none" }}
            />
          ) : (
            "估價單.pdf"
          )}
        </Button>
      </>
    );
  }
}

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: ${props => props.color || Theme.colors.main};
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;
  margin-left: 20px;

  ${props => props.css}
`;

export default PdfDownloadButton;
