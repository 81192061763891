import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import Expander from "./Expander";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { connect } from "react-redux";
import OrderTotalAmount from "../Components/OrderTotalAmount";
import OrderHintBlock from "../Components/OrderHintBlock";
import InvoiceForm from "../Components/InvoiceForm";
import * as Theme from "../Theme";
import { isVIP, isMonthly } from "../Domain/RoleValidator";
import {
  isPersonCertificatedCode,
  isMobileVehicleCode,
  isCompanyId,
  isLoveCode,
} from "../Domain/FormatValidator";
const validator = require("validator");

class CheckoutForm extends React.Component {
  render() {
    const {
      goToPrevStep,
      goToNextStep,
      config,
      setConfig,
      profile,
      cart,
      note,
      editNote,
      shouldDoValidate,
      toCheckHint, //function({checkHintX: boolean})
    } = this.props;

    return (
      <div>
        <Expander title="會員資料">
          <UserInfoForm
            config={config}
            profile={profile}
            setConfig={config => setConfig(config)}
            doValidate={shouldDoValidate}
          />
        </Expander>

        <Expander title="發票資料">
          <InvoiceForm
            config={config}
            setConfig={config => setConfig(config)}
            doValidate={shouldDoValidate}
            showHint={true}
          />
        </Expander>

        <div style={{ marginBottom: 20 }}>
          <Ant.Row gutter={16}>
            <Ant.Col xs={24} sm={24} md={16} lg={16}>
              <OrderHintBlock
                note={note}
                editNote={editNote}
                toCheckHint={hintObj => toCheckHint(hintObj)}
              />
            </Ant.Col>
            <Ant.Col xs={24} sm={24} md={8} lg={8}>
              <OrderTotalAmount calculations={cart.data.calculations} />
            </Ant.Col>
          </Ant.Row>
        </div>

        <Footer>
          <Ant.Button style={{ flexGrow: 0 }} onClick={goToPrevStep}>
            回上一步
          </Ant.Button>
          <div style={{ flex: 1 }}></div>
          <Ant.Button
            type="primary"
            style={{
              flexGrow: 0,
              backgroundColor: Theme.colors.main,
              border: 0,
            }}
            onClick={goToNextStep}
          >
            下一步
          </Ant.Button>
        </Footer>
      </div>
    );
  }
}

class UserInfoForm extends React.Component {
  render() {
    const { profile, config, setConfig, doValidate } = this.props;

    return (
      <>
        <FieldRow>
          <Label>會員名稱</Label>
          <InputContainer>
            <TextInput
              placeholder="會員名稱"
              defaultValue={profile.name || ""}
              value={config.name}
              onChange={e => setConfig({ ...config, name: e.target.value })}
            />
            {doValidate &&
              validator.isEmpty(config.name) &&
              ErrorHint({ message: "會員名稱不能為空" })}
          </InputContainer>
        </FieldRow>

        <FieldRow>
          <Label>公司名稱</Label>
          <InputContainer>
            <TextInput
              placeholder="公司名稱"
              defaultValue={profile.company || ""}
              value={config.company}
              onChange={e => setConfig({ ...config, company: e.target.value })}
            />
            {doValidate &&
              validator.isEmpty(config.company) &&
              ErrorHint({ message: "公司名稱不能為空" })}
          </InputContainer>
        </FieldRow>

        <FieldRow>
          <Label>手機號碼</Label>
          <InputContainer>
            <TextInput
              placeholder="手機號碼"
              defaultValue={profile.phone || ""}
              value={config.phone || profile.phone}
              onChange={e => setConfig({ ...config, phone: e.target.value })}
            />
            {doValidate &&
              (validator.isEmpty(config.phone) ||
                !validator.isNumeric(config.phone)) &&
              ErrorHint({ message: "請確認手機號碼格式" })}
          </InputContainer>
        </FieldRow>

        <FieldRow>
          <Label>市話號碼</Label>
          <InputContainer>
            <TextInput
              placeholder="市話"
              value={config.tel}
              onChange={e => setConfig({ ...config, tel: e.target.value })}
            />
            <TextInput
              placeholder="分機"
              type="short"
              value={config.tel_ext}
              onChange={e => setConfig({ ...config, tel_ext: e.target.value })}
            />
          </InputContainer>
        </FieldRow>

        <FieldRow>
          <Label>電子信箱</Label>
          <InputContainer>
            <TextInput
              placeholder="電子信箱"
              type="long"
              defaultValue={profile.email || ""}
              value={config.email || profile.email}
              onChange={e => setConfig({ ...config, email: e.target.value })}
            />
            {doValidate &&
              validator.isEmpty(config.email) &&
              ErrorHint({ message: "電子信箱不能為空" })}
          </InputContainer>
        </FieldRow>

        <FieldRow>
          <Label>通訊地址</Label>
          <InputContainer>
            <TextInput
              placeholder="郵遞區號"
              type="short"
              value={config.zip}
              onChange={e => setConfig({ ...config, zip: e.target.value })}
            />
            <TextInput
              placeholder="通訊地址"
              type="long"
              defaultValue={profile.address || ""}
              value={config.address}
              onChange={e => setConfig({ ...config, address: e.target.value })}
            />
            {doValidate &&
              validator.isEmpty(config.address) &&
              ErrorHint({ message: "通訊地址不能為空" })}
          </InputContainer>
        </FieldRow>
      </>
    );
  }
}

class DeliveryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        deliveryType: props.config.delivery_type,
        isDeliveryPrivate: props.config.is_delivery_private,
      },
    };
  }

  render() {
    const { values } = this.state;
    const {
      config,
      setConfig,
      userConfig,
      profile,
      doValidate,
      updateDeliveryType,
    } = this.props;

    return (
      <>
        <div style={{ height: 10 }} />

        <Ant.Radio.Group
          onChange={e => {
            this.setState({
              values: { ...values, deliveryType: e.target.value },
            });
            //it's a special case(need to update cart when edit delivery type)
            updateDeliveryType("delivery", {
              ...config,
              delivery_type: e.target.value,
            });
          }}
          value={values.deliveryType}
        >
          <RadioBtn value="hct">宅配運送</RadioBtn>
          {/* <Ant.Radio value="mailing">郵寄</Ant.Radio> */}
          <RadioBtn value="self_pick">自行取貨</RadioBtn>
          {/* <Ant.Radio value="special_car">專車配送</Ant.Radio> */}
        </Ant.Radio.Group>

        <div
          style={{ height: 1, backgroundColor: "#ddd", margin: "15px 0px" }}
        />

        {values.deliveryType === "hct" && (
          <div>
            <Checkbox
              checked={values.is_delivery_private}
              onChange={e => {
                setConfig({
                  ...config,
                  is_delivery_private: !config.is_delivery_private,
                });
                this.setState({
                  values: { ...values, is_delivery_private: e.target.checked },
                });
              }}
            >
              保密代寄
            </Checkbox>
            {values.is_delivery_private && (
              <>
                <Title>
                  寄件人資料
                  <Ant.Button
                    type="primary"
                    style={{
                      flexGrow: 0,
                      backgroundColor: Theme.colors.main,
                      border: 0,
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      setConfig({
                        ...config,
                        sender_name: userConfig.name,
                        sender_phone: userConfig.phone,
                        sender_tel: userConfig.tel,
                        sender_zip: userConfig.zip,
                        sender_address: userConfig.address,
                      });
                    }}
                  >
                    同會員資料
                  </Ant.Button>
                </Title>
                <FieldRow>
                  <Label>寄件人名</Label>
                  <InputContainer>
                    <TextInput
                      placeholder="寄件人名"
                      value={config.sender_name}
                      onChange={e =>
                        setConfig({ ...config, sender_name: e.target.value })
                      }
                    />
                    {doValidate &&
                      validator.isEmpty(config.sender_name) &&
                      ErrorHint({ message: "寄件人名不能為空" })}
                  </InputContainer>
                </FieldRow>

                <FieldRow>
                  <Label>手機號碼</Label>
                  <InputContainer>
                    <TextInput
                      placeholder="手機號碼"
                      value={config.sender_phone}
                      onChange={e =>
                        setConfig({ ...config, sender_phone: e.target.value })
                      }
                    />
                    {doValidate &&
                      (validator.isEmpty(config.sender_phone) ||
                        !validator.isNumeric(config.sender_phone)) &&
                      ErrorHint({ message: "請確認手機號碼格式" })}
                  </InputContainer>
                </FieldRow>

                <FieldRow>
                  <Label>市話號碼</Label>
                  <InputContainer>
                    <TextInput
                      placeholder="市話"
                      value={config.sender_tel}
                      onChange={e =>
                        setConfig({ ...config, sender_tel: e.target.value })
                      }
                    />
                    <TextInput
                      placeholder="分機"
                      type="short"
                      value={config.sender_tel_ext}
                      onChange={e =>
                        setConfig({ ...config, sender_tel_ext: e.target.value })
                      }
                    />
                  </InputContainer>
                </FieldRow>

                <FieldRow>
                  <Label>寄件地址</Label>
                  <InputContainer>
                    <TextInput
                      placeholder="郵遞區號"
                      type="short"
                      value={config.sender_zip}
                      onChange={e =>
                        setConfig({ ...config, sender_zip: e.target.value })
                      }
                    />
                    <TextInput
                      placeholder="通訊地址"
                      type="long"
                      value={config.sender_address}
                      onChange={e =>
                        setConfig({ ...config, sender_address: e.target.value })
                      }
                    />
                    {doValidate &&
                      validator.isEmpty(config.sender_address) &&
                      ErrorHint({ message: "寄件地址不能為空" })}
                  </InputContainer>
                </FieldRow>
              </>
            )}
            <Title>
              收件人資料
              <Ant.Button
                type="primary"
                style={{
                  flexGrow: 0,
                  backgroundColor: Theme.colors.main,
                  border: 0,
                  marginLeft: 10,
                }}
                onClick={() => {
                  setConfig({
                    ...config,
                    receiver_name: userConfig.name,
                    receiver_phone: userConfig.phone,
                    receiver_tel: userConfig.tel,
                    zip: userConfig.zip,
                    receiver_address: userConfig.address,
                  });
                }}
              >
                同會員資料
              </Ant.Button>
            </Title>
            <FieldRow>
              <Label>收件人名</Label>
              <InputContainer>
                <TextInput
                  placeholder="收件人名"
                  value={config.receiver_name}
                  onChange={e =>
                    setConfig({ ...config, receiver_name: e.target.value })
                  }
                />
                {doValidate &&
                  validator.isEmpty(config.receiver_name) &&
                  ErrorHint({ message: "收件人名不能為空" })}
              </InputContainer>
            </FieldRow>

            <FieldRow>
              <Label>手機號碼</Label>
              <InputContainer>
                <TextInput
                  placeholder="手機號碼"
                  value={config.receiver_phone}
                  onChange={e =>
                    setConfig({ ...config, receiver_phone: e.target.value })
                  }
                />
                {doValidate &&
                  (validator.isEmpty(config.receiver_phone) ||
                    !validator.isNumeric(config.receiver_phone)) &&
                  ErrorHint({ message: "請確認手機號碼格式" })}
              </InputContainer>
            </FieldRow>

            <FieldRow>
              <Label>市話號碼</Label>
              <InputContainer>
                <TextInput
                  placeholder="市話"
                  value={config.receiver_tel}
                  onChange={e =>
                    setConfig({ ...config, receiver_tel: e.target.value })
                  }
                />
                <TextInput
                  placeholder="分機"
                  type="short"
                  value={config.receiver_tel_ext}
                  onChange={e =>
                    setConfig({ ...config, receiver_tel_ext: e.target.value })
                  }
                />
              </InputContainer>
            </FieldRow>

            <FieldRow>
              <Label>備用電話</Label>
              <InputContainer>
                <TextInput
                  placeholder="備用電話"
                  value={config.receiver_phone_spare}
                  onChange={e =>
                    setConfig({
                      ...config,
                      receiver_phone_spare: e.target.value,
                    })
                  }
                />
              </InputContainer>
            </FieldRow>

            <FieldRow>
              <Label>收件地址</Label>
              <InputContainer>
                <TextInput
                  placeholder="郵遞區號"
                  type="short"
                  value={config.zip}
                  onChange={e => setConfig({ ...config, zip: e.target.value })}
                />
                <TextInput
                  placeholder="通訊地址"
                  type="long"
                  value={config.receiver_address}
                  onChange={e =>
                    setConfig({ ...config, receiver_address: e.target.value })
                  }
                />
                {doValidate &&
                  validator.isEmpty(config.receiver_address) &&
                  ErrorHint({ message: "通訊地址不能為空" })}
              </InputContainer>
            </FieldRow>

            <FieldRow>
              <Label>備註</Label>
              <InputContainer>
                <TextInput
                  placeholder="備註"
                  value={config.delivery_note}
                  onChange={e =>
                    setConfig({ ...config, delivery_note: e.target.value })
                  }
                />
              </InputContainer>
            </FieldRow>
          </div>
        )}

        {values.deliveryType === "self_pick" && (
          <div>
            <div>
              印刷品完成時，本網站將會發送一封商品完成的簡訊與e-mail通知，收到通知後，
            </div>
            <div>
              請先與客服預約確認前往取貨時間，請持簡訊或e-mail通知，於預約時間及指定地點前往取貨。
            </div>

            <div style={{ height: 20 }} />

            <div>取貨時間： 星期一到五：10:00-19:00 (國定例假日休息)</div>
            <div>
              取貨地點：
              詳細取貨地點，請於下單前電話或利用以下客服管道預約取貨，謝謝
            </div>

            <ul style={{ paddingLeft: 30, paddingTop: 10 }}>
              <li>客服電話：02-2736-7701</li>
              <li>Facebook 粉絲頁：理想印制Li Xiang Print</li>
              <li>LINE@ ：專人LINE詢問 @li_xiang_print</li>
              <li>Email 客服：service@lixiangprint.com.tw</li>
            </ul>
          </div>
        )}
      </>
    );
  }
}

const ErrorHint = ({ message }) => {
  return <Alert message={message} type="warning" showIcon />;
};

const Alert = styled(Ant.Alert)`
  .ant-alert-message {
    color: #6b6b6b;
  }
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  ${props => props.css}
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TextInput = styled(Ant.Input)`
  margin: 5px;
  flex-basis: 220px;
  ${props => (props.type === "short" ? "flex-basis: 90px; width: 90px;" : "")}
  ${props => (props.type === "long" ? "flex-basis: 320px;" : "")}

  :hover {
    border-color: ${Theme.colors.main}4;
  }

  :focus {
    border-color: ${Theme.colors.main};
    outline-color: ${Theme.colors.main}4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }

  .ant-input::selection {
    border-color: green;
  }

  /* ::after {
    border-color: green;
    background: green;
} */
`;

const SelectInput = styled(Ant.Select)`
  margin: 5px;
  flex-basis: 150px;
`;

const SelectInputOption = styled(Ant.Select.Option)`
  width: 150px;
`;

const Label = styled.label`
  margin-right: 10px;
  flex-basis: 60px;
  flex-shrink: 0;
`;

const Title = styled.p`
  color: #3e3a39;
  font-size: 18px;
  margin: 20px 0px;
`;

const RadioBtn = styled(Ant.Radio)`
  .ant-radio-checked .ant-radio-inner {
    color: ${Theme.colors.main};
    border-color: ${Theme.colors.main};
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
  .ant-radio-inner::after {
    background-color: ${Theme.colors.main};
    color: ${Theme.colors.main};
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: ${Theme.colors.main};
    color: ${Theme.colors.main};
  }
`;

const Checkbox = styled(Ant.Checkbox)`
  /* TODO: how to change hover border color when checked ? */
  .ant-checkbox-checked :hover {
    /* it isn't work */
    border-color: ${Theme.colors.main};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Theme.colors.main};
    border-color: ${Theme.colors.main};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${Theme.colors.main};
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin-bottom: 100px;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(CheckoutForm);
