import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import * as Icon from "./Icon";

class Expander extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true,
    };
  }

  render() {
    const { expand } = this.state;
    const { title } = this.props;

    return (
      <>
        <SectionExpand
          expand={expand}
          onClick={() => this.setState({ expand: !expand })}
        >
          <div style={{ flex: 1 }}>{title}</div>
          {expand ? (
            <Icon.ExpandLess size={24} color="black" />
          ) : (
            <Icon.ExpandMore size={24} color="black" />
          )}
        </SectionExpand>

        {expand && <div style={{ padding: 10 }}>{this.props.children}</div>}
      </>
    );
  }
}

const SectionExpand = styled.div`
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.expand ? "0px" : "20px")};
`;

export default Expander;
