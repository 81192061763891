import React, { Fragment } from "react";
import styled from "styled-components";
import * as Cart from "../Contexts/CartContext";
import {
  DialogWrapper,
  Input,
  Button,
  Selector,
  TextArea,
  Title,
} from "../Components/DialogWidgets";
import * as Icon from "../Components/Icon";
import * as Ant from "antd";

class ReturnApplicationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newFile: null,
      record: {
        id: null,
        order: null,
        status: "",
        note: "",
        file: "",
        item_index: 0,
      },
    };
  }

  render() {
    let { closeDialog, product } = this.props;
    let { record, newFile, order } = this.state;
    console.log("record!!!!!!", record);
    return (
      <Ant.Modal
        visible={true}
        title={<Title>退貨申請單</Title>}
        footer={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button css="margin: 10px;" onClick={() => closeDialog()}>
              取消
            </Button>
            <Button css="margin: 10px;" onClick={() => this._createRecord()}>
              確定
            </Button>
          </div>
        }
        closeIcon={
          <Icon.Close
            style={{ position: "absolute", right: 20, top: 20 }}
            size={20}
            onClick={() => closeDialog()}
          />
        }
        onCancel={closeDialog}
        style={{
          textAlign: "center",
        }}
        width="80%"
      >
        <DialogWrapper>
          <div className="content">
            <div className="row">
              <h4>商品名稱</h4>
              <p>{product.name}</p>
            </div>
            <div className="row">
              <h4>檔案</h4>
              <>
                <input
                  onClick={e => e.stopPropagation()}
                  type="file"
                  onChange={e => this.setState({ newFile: e.target.files[0] })}
                />
                <h5 style={{ color: "#009688" }}>{record.file} </h5>
              </>
            </div>
            <div className="row">
              <h4>備註</h4>
              <TextArea
                value={record.note ? record.note : ""}
                style={{ marginRight: 10 }}
                onChange={e =>
                  this.setState({ record: { ...record, note: e.target.value } })
                }
              />
            </div>
          </div>
        </DialogWrapper>
      </Ant.Modal>
    );
  }

  _createRecord = async () => {
    let { record, newFile } = this.state;
    let { closeDialog, appActions, orderItem, order, onUpdate } = this.props;

    try {
      await Cart.Actions.createReturnApp({
        order: orderItem.order,
        item_index: orderItem.item_index,
        status: "pending",
        note: record.note,
        file: newFile,
      });
      onUpdate();
      closeDialog();
    } catch (err) {
      console.warn(err);
    }
  };
}

export default ReturnApplicationDialog;
