import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Cart from "../Contexts/CartContext";
import {
  DialogWrapper,
  Input,
  Button,
  Selector,
  TextArea,
  Title,
} from "./DialogWidgets";
import * as Icon from "./Icon";
import moment from "moment";
import * as Ant from "antd";

class RefundDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        status: "",
        note: "",
        amount: "",
        order: null,
        issuer: null,
        order_owner: null,
        created: null,
        item_index: 0,
      },
    };
  }

  render() {
    let { closeDialog, product } = this.props;
    let { record } = this.state;

    return (
      <Ant.Modal
        visible={true}
        title={<Title>退款申請單</Title>}
        footer={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button css="margin: 10px;" onClick={() => closeDialog()}>
              取消
            </Button>
            <Button
              css="margin: 10px;"
              onClick={() => {
                this._createRecord();
              }}
            >
              確定
            </Button>
          </div>
        }
        closeIcon={
          <Icon.Close
            style={{ position: "absolute", right: 20, top: 20 }}
            size={20}
          />
        }
        onCancel={closeDialog}
        style={{
          textAlign: "center",
        }}
        width="80%"
      >
        <DialogWrapper>
          <div className="content">
            <div className="row">
              <h4>商品名稱</h4>
              <p>{product.name}</p>
            </div>

            <div className="row">
              <h4>備註</h4>
              <TextArea
                value={record.note}
                style={{ marginRight: 10 }}
                onChange={e =>
                  this.setState({ record: { ...record, note: e.target.value } })
                }
              />
            </div>
          </div>
        </DialogWrapper>
      </Ant.Modal>
    );
  }

  _createRecord = async () => {
    let { record } = this.state;
    let { closeDialog, onUpdate, orderItem } = this.props;

    try {
      await Cart.Actions.createRefund({
        status: "waiting",
        note: record.note,
        amount: 0,
        order: orderItem.order,
        item_index: orderItem.item_index,
      });
      onUpdate();
      closeDialog();
    } catch (err) {
      console.warn(err);
    }
  };
}

export default RefundDialog;
