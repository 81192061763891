import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { withPage } from "../PageContainer";
import * as Cart from "../Contexts/CartContext";
import CartStepTabs from "../Components/CartStepTabs";
import CartDetail from "../Components/CartDetail";
import CheckoutForm from "../Components/CheckoutForm";
import OrderPreview from "../Components/OrderPreview";
import {
  isPersonCertificatedCode,
  isMobileVehicleCode,
  isCompanyId,
  isLoveCode,
  isNotEmpty,
  isEmail,
  isMobileNumber,
} from "../Domain/FormatValidator";
import * as AppEvents from "../AppEvents";
import * as Ant from "antd";
const validator = require("validator");
import {
  CATEGORIES,
  CARRIER_TYPE,
  valid as invoiceValid,
} from "../Components/InvoiceForm";
import { TruckDimensions } from "styled-icons/remix-line";

class CartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      afterClickNext: false,
      step: 0,
      checkHint1: false,
      loading: true,
      // userConfig: {
      //   name: "",
      //   phone: "",
      //   email: "",
      //   zip: "",
      //   tel: "",
      //   tel_ext: "",
      //   address: "",
      // },
      // invoiceConfig: {
      //   invoice_type: ALL_SUPPORTED_RECEIPT_TYPE.two_copies.value,
      //   invoice_subtype: RECEIPT_TWO_COPIES_TYPES.ezpay_vehicle.value,
      //   invoice_detail: "",

      //   citizen_personal_certificate_code: "", //自然人憑證條碼(16碼)
      //   mobile_vehicle_code: "", //手機載具(8碼)
      //   ezpay_vehicle_code: "",

      //   gui_number: "",
      //   company_title: "",

      //   donate_foundation_gui: "",
      //   love_code: "",
      // },
      checkoutParams: {
        title: "Order",
        payment_type: "neweb",
        payment_subtype: "",
        note: "",
      },
      config: {
        name: "",
        email: "",
        phone: "",
        company: "",
        address: "",
        // logistic_provider: "",
        // logistic_type: "",
        // logistic_subtype: "",
        // CVSStoreID: "",
        category: CATEGORIES.two_copies.value,
        uni_no: "",
        company_title: "",
        carrier_type: CARRIER_TYPE.default.value,
        carrier_num: "",
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.cart.data && this.props.cart.data) {
      this._autofill();
    }

    if (!prevProps.cart.loading && this.props.cart.loading) {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    if (!this.props.profile && !this.props.autologin) {
      Ant.message.warning("請先登入");
    }
  }

  render() {
    const { cart } = this.props;
    const {
      step,
      config,
      checkoutParams,
      afterClickNext,
      loading,
    } = this.state;
    if (loading) {
      return null;
    }
    if (!cart.data || cart.data.items.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="../../images/error-cart.png"
            width="50%"
            style={{ maxWidth: 400, marginBottom: 30, marginTop: 50 }}
          />
          <p
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 5,
              paddingRight: 40,
              paddingLeft: 40,
            }}
          >
            您尚未將任何課程加入購物車，
          </p>
          <p style={{ fontSize: 16, fontWeight: "bold" }}>
            歡迎至課程列表購買課程！
          </p>
        </div>
      );
    }

    console.log("state-->", this.state);
    return (
      <Wrapper>
        <Ant.Spin spinning={loading}>
          <div className="center-content">
            <CartStepTabs step={step}>
              {step === 0 && (
                <CartDetail
                  cartData={cart.data}
                  goToNextStep={this._goToNextStep}
                />
              )}

              {step === 1 && (
                <CheckoutForm
                  shouldDoValidate={afterClickNext}
                  toCheckHint={hintObj => this.setState(hintObj)}
                  cart={cart} // for display total amount
                  config={config}
                  setConfig={obj => {
                    this.setState({
                      config: {
                        ...this.state.config,
                        ...obj,
                      },
                      afterClickNext: false, // to make warning hint not to show every setState
                    });
                  }}
                  note={checkoutParams.note}
                  editNote={note =>
                    this.setState({
                      ...this.state,
                      checkoutParams: { ...this.state.checkoutParams, note },
                    })
                  }
                  goToPrevStep={this._goToPrevStep}
                  goToNextStep={async () => {
                    this.setState({ afterClickNext: true });
                    this._editConfig();
                  }}
                />
              )}

              {step === 2 && (
                <OrderPreview
                  viewOnly
                  cartData={cart.data}
                  goToPrevStep={this._goToPrevStep}
                  goToNextStep={this._checkout}
                  /* for edit payment type */
                  editCheckoutParams={payment_subtype => {
                    this.setState({
                      ...this.state,
                      checkoutParams: {
                        ...this.state.checkoutParams,
                        payment_subtype,
                      },
                    });
                  }}
                />
              )}
            </CartStepTabs>
          </div>
        </Ant.Spin>
      </Wrapper>
    );
  }

  _autofill = () => {
    let { cart, profile } = this.props;
    let config = { ...this.state.config };

    if (cart.data) {
      config = {
        ...config,
        ...cart.data.config,
      };
    }

    if (profile) {
      config.name = config.name || profile.name;
      config.company = config.company || profile.company;
      config.phone = config.phone || profile.phone;
      config.email = config.email || profile.email;
      config.address = config.address || profile.address;
    }

    this.setState({ config });
  };

  _editConfig = async () => {
    const { config } = this.state;
    if (!this._checkAgreeSections()) {
      Ant.message.warn("請閱讀且同意注意事項");
      return;
    }

    try {
      this._valid();
      await Cart.Actions.editConfig(config);

      this._autofill();
      this._goToNextStep();
    } catch (ex) {
      console.warn("Edit Config Failure", ex);
      Ant.message.error("欄位輸入錯誤");
    }
  };

  _checkAgreeSections = () => {
    let { checkHint1 } = this.state;
    if (checkHint1) {
      return true;
    }
    return false;
  };

  _valid = () => {
    // user config
    let { name, phone, address, company, email } = this.state.config;

    if (
      !isNotEmpty(name) ||
      !isNotEmpty(company) ||
      !isNotEmpty(address) ||
      !isNotEmpty(email) ||
      !isNotEmpty(phone)
    ) {
      Ant.message.error("請填入會員資料必填欄位");
      throw Error;
    }
    if (!isEmail(email)) {
      Ant.message.error("會員資料電子信箱格式錯誤");
      throw Error;
    }
    if (!isMobileNumber(phone)) {
      Ant.message.error("會員資料手機格式錯誤");
      throw Error;
    }

    // invoice config
    invoiceValid(this.state.config);
  };

  _checkout = async () => {
    const { navActions } = this.props;
    const { checkoutParams } = this.state;
    this.setState({ loading: true });

    try {
      Cart.Actions.checkout({
        ...checkoutParams,
      });
    } catch (ex) {
      alert("checkout failure", ex);
    }
    this.setState({ loading: false });
  };

  _goToNextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 }, () => {
      window.scrollTo(0, 0);
    });
  };

  _goToPrevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 }, () => {
      window.scrollTo(0, 0);
    });
  };
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;

  .center-content {
    max-width: 960px;
    margin: 20px auto;
  }
`;

export default withPage(
  Cart.withConsumer(
    connect(
      (state, ownProps) => ({
        profile: Selectors.getLoginUser(state),
        autologin: Selectors.autologinProcessing(state),
      }),
      ActionCreator
    )(CartPage)
  )
);
