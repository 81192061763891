import React from "react";
import * as Theme from "../Theme";

class OrderTotalAmount extends React.Component {
  render() {
    const { calculations } = this.props;
    let {
      items_amount, //商品金額（未稅）
      discount, //活動折抵
      fee, //運費(未稅)
      fee_tax, //運費稅
      tax,
      amount, //訂單總計金額(含稅)
    } = calculations;
    // console.log("items_amount", items_amount);
    // console.log("discount", discount);
    // console.log("fee", fee);
    // console.log("fee_tax", fee_tax);
    // console.log("tax", tax);
    // console.log("amount", amount);

    return (
      <div
        style={{
          borderRadius: 5,
          backgroundColor: "white",
          boxShadow: "0px 2px 13px -5px #999",
        }}
      >
        <div style={{ padding: 15 }}>
          <div
            style={{
              display: "flex",
              padding: "8px 0",
              borderBottom: "1px solid #ddd",
            }}
          >
            <div style={{ flex: 1 }}>課程金額（未稅）</div>
            <div style={{ fontWeight: "bold" }}>$ {items_amount}</div>
          </div>

          {/* <div
            style={{
              display: "flex",
              padding: "8px 0",
              borderBottom: "1px solid #ddd",
            }}
          >
            <div style={{ flex: 1 }}>活動折抵（未稅）</div>
            <div
              style={{
                fontWeight: "bold",
                color: (discount !== 0 && "#ff4d4f") || "",
              }}
            >
              {`$ ${discount}`}
            </div>
          </div> */}

          <div
            style={{
              display: "flex",
              padding: "8px 0",
            }}
          >
            <div style={{ flex: 1 }}>營業稅</div>
            <div style={{ fontWeight: "bold" }}>$ {tax}</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 15px",

            borderTop: `1px solid ${Theme.colors.main}`,
          }}
        >
          <div style={{ flex: 1 }}>訂單總計金額（含稅）</div>
          <div
            style={{
              fontWeight: "bold",
              color: Theme.colors.main,
              fontSize: 24,
            }}
          >
            $ {amount}
          </div>
        </div>
      </div>
    );
  }
}

export default OrderTotalAmount;
