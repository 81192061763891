import React, { Fragment } from "react";
import * as Ant from "antd";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { connect } from "react-redux";
import CartDetail from "./CartDetail";
import OrderTotalAmount from "./OrderTotalAmount";
import PaymentTypeOptions from "./PaymentTypeOptions";
import * as Theme from "../Theme";
import { parseInvoice } from "./InvoiceForm";
import * as Widget from "./Widget";

export const PAYMENT_STATUS = {
  processing: "未付款",
  waiting: "未付款",
  code_generated: "未付款", //已取得交易序號
  success: "已付款",
  failure: "付款失敗",
};

class OrderPreview extends React.Component {
  render() {
    let {
      cartData,
      goToPrevStep,
      goToNextStep,
      viewOnly,
      editCheckoutParams,
    } = this.props;

    return (
      <>
        <Widget.PillHeader first style={{ marginBottom: 30 }}>
          課程資訊
        </Widget.PillHeader>
        <CartDetail cartData={cartData} viewOnly={viewOnly} />
        <Widget.PillHeader>訂單資訊</Widget.PillHeader>
        <SectionWrapper>
          <Row>
            <Label>會員名稱：</Label>
            <Value>{cartData.config.name}</Value>
          </Row>

          <Row>
            <Label>手機號碼：</Label>
            <Value>{cartData.config.phone}</Value>
          </Row>

          <Row>
            <Label>電子信箱：</Label>
            <Value>{cartData.config.email}</Value>
          </Row>

          <Row>
            <Label>通訊地址：</Label>
            <Value>{cartData.config.address}</Value>
          </Row>
        </SectionWrapper>
        {goToNextStep && (
          <>
            <Widget.PillHeader>付款方式：</Widget.PillHeader>
            <SectionWrapper>
              <PaymentTypeOptions
                editCheckoutParams={editCheckoutParams}
                cartData={cartData}
              />
            </SectionWrapper>
          </>
        )}
        {this._renderPaymentData()}
        {this._renderInvoiceData()}

        <div style={{ marginBottom: 20 }}>
          <Ant.Row gutter={16}>
            <Ant.Col xs={24} sm={24} md={16} lg={16} />
            <Ant.Col xs={24} sm={24} md={8} lg={8}>
              <OrderTotalAmount calculations={cartData.calculations} />
            </Ant.Col>
          </Ant.Row>
        </div>

        {goToNextStep && (
          <Footer>
            <Ant.Button style={{ flexGrow: 0 }} onClick={goToPrevStep}>
              回上一步
            </Ant.Button>
            <div style={{ flex: 1 }}></div>
            <Ant.Button
              type="primary"
              style={{
                flexGrow: 0,
                backgroundColor: Theme.colors.main,
                border: 0,
              }}
              onClick={goToNextStep}
            >
              送出訂單
            </Ant.Button>
          </Footer>
        )}
      </>
    );
  }

  _renderPaymentData = () => {
    let { order } = this.props;
    let paymentData = null;
    if (order && order.payment_transaction_detail_1) {
      paymentData = JSON.parse(order.payment_transaction_detail_1);
    }
    if (!order) {
      return null;
    }
    return (
      <>
        <Widget.PillHeader>付款資訊</Widget.PillHeader>

        <SectionWrapper>
          {(() => {
            if (order) {
              return (
                <Row>
                  <Label>付款方式：</Label>
                  <Value>
                    {" "}
                    {
                      {
                        credits: "點數付款",
                        neweb: "藍新付款",
                        offline: "待臨櫃匯款確認",
                      }[order.payment_type]
                    }{" "}
                    {order.payment_type === "neweb" &&
                      {
                        credit: "信用卡付款",
                        cvs_cod: "超商取貨付款",
                        cvs: "超商繳費",
                        atm: "ATM付款",
                        web_atm: "網路ATM付款",
                        barcode: "超商條碼繳費",
                      }[order.payment_subtype]}
                  </Value>
                </Row>
              );
            }
          })()}
          {order &&
            order.payment_status !== "success" &&
            order.payment_status !== "failure" &&
            order.bank_code && (
              <Row>
                <Label>
                  {
                    {
                      atm: "轉帳帳號：",
                      cvs: "超商繳費代碼：",
                    }[order.payment_subtype]
                  }
                </Label>
                {order.bank_code &&
                  " 銀行代碼 " +
                    order.bank_code +
                    " - 帳號 " +
                    order.bank_account}
                <Value> {order.code_no}</Value>
              </Row>
            )}
          {order && (
            <Row>
              <Label>付款狀態：</Label>
              <Value> {PAYMENT_STATUS[order.payment_status]}</Value>
            </Row>
          )}
        </SectionWrapper>
      </>
    );
  };

  _renderInvoiceData = () => {
    let { order, cartData } = this.props;
    let data = parseInvoice({ order, cart: cartData });
    return (
      <>
        <Widget.PillHeader>發票資訊</Widget.PillHeader>

        <SectionWrapper>
          {data.map((item, idx) => (
            <Row key={idx}>
              <Label>{item.label}</Label>
              <Value>{item.value}</Value>
            </Row>
          ))}
          {order && order.invoice_transaction_detail_1 && (
            <Ant.Button
              type="primary"
              href={`https://inv.ezpay.com.tw/Invoice_index/search_platform`}
              style={{
                flexGrow: 0,
                backgroundColor: Theme.colors.main,
                border: 0,
              }}
            >
              下載發票證明聯
            </Ant.Button>
          )}
        </SectionWrapper>
      </>
    );
  };
}

const SectionHeader = styled.p`
  color: #5a5a5a;
  border: 1px solid #ccc;
  padding: 10px 20px;
  /* text-align: center; */
  margin-top: 25px;
  margin-bottom: 10px;
  background-color: #f7f7f7;
  ${props => (props.first ? "margin-top: 0px;" : "")}
`;

const SectionWrapper = styled.div`
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Value = styled.div`
  flex-basis: 220px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${props => (props.type === "short" ? "flex-basis: 90px; width: 90px;" : "")}
  ${props => (props.type === "long" ? "flex-basis: 320px;" : "")}
`;

const Label = styled.label`
  margin-right: 10px;
  /* flex-basis: 100px;
  flex-shrink: 0; */
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin-bottom: 100px;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(OrderPreview);
