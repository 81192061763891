import React from "react";
import styled from "styled-components";
import * as Theme from "../Theme";

class CartStepTabs extends React.Component {
  render() {
    const { step, onTabClick = () => 0 } = this.props;

    return (
      <Wrapper>
        <TabBar>
          <TabItem isActived={step >= 0} onClick={() => onTabClick(0)}>
            <div className="point">
              <p>1</p>
            </div>
            購物車
          </TabItem>
          <TabItem isActived={step >= 1} onClick={() => onTabClick(1)}>
            <div className="line"></div>
            <div className="point">
              <p>2</p>
            </div>
            訂購資訊
          </TabItem>
          <TabItem isActived={step >= 2} onClick={() => onTabClick(2)}>
            <div className="line"></div>
            <div className="point">
              <p>3</p>
            </div>
            選擇付款方式
          </TabItem>
          <TabItem isActived={step >= 3} onClick={() => onTabClick(3)}>
            <div className="line"></div>
            <div className="point">
              <p>4</p>
            </div>
            訂單成立且付款
          </TabItem>
        </TabBar>

        <TabContent>{this.props.children}</TabContent>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: white;
`;

const TabBar = styled.div`
  display: flex;
`;

const TabItem = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  flex-direction: column;

  & > .line {
    position: absolute;
    width: calc(100% - 40px); /* not confirm */
    height: 2px;
    top: 20px;
    right: calc(50% + 20px);
    background-color: ${props =>
      props.isActived ? Theme.colors.main : "#cbcbcb"};
  }

  & > .point {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: ${props =>
      props.isActived ? Theme.colors.main : "#cbcbcb"};
    background: ${Theme.colors.main};
    ${props => !props.isActived && "background: #cbcbcb;"}
    & > p {
      color: white;
      margin-bottom: 0px;
    }
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 30px;

  & > p {
    font-size: 13px;
    color: ${props => (props.isActived ? "#444444" : "#cbcbcb")};
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  & > .title {
    font-size: 14px;
    color: #595757;
  }

  & > .subtitle {
    font-size: 12px;
    color: #595757;
  }

  & > .data {
    font-size: 8px;
    color: #9fa0a0;
    text-align: center;
  }

  & > .hint {
    color: red;
  }
`;

const TabContent = styled.div`
  min-height: 200px;
  padding-top: 20px;
`;

export default CartStepTabs;
