import React from "react";
import styled from "styled-components";
import ProductParam, { getDefaultConfigWithExtra } from "./ProductParam";
import * as Cart from "../Contexts/CartContext";
import * as Catalog from "../Contexts/CatalogContext";

class ProductConfig extends React.Component {
  render() {
    const { product, config, itemIndex, viewOnly } = this.props;
    const selectNames = Catalog.Actions.getProductSelectNames(product);

    return (
      <Wrapper>
        {selectNames.map(selectName => (
          <ProductParam
            key={selectName}
            selectName={selectName}
            param={product[selectName]}
            value={config[selectName]}
            setValue={value => {
              let nextConfig = {
                ...config,
                [selectName]: value,
              };

              if (selectName === "side_select") {
                alert("由於您修改了單雙面設定, 請重新選擇其餘選項");
                const [nextConfigWithSide] = getDefaultConfigWithExtra({
                  product,
                  side: value,
                });
                nextConfig = {
                  ...nextConfigWithSide,
                  side_select: value,
                };
              } else if (product[selectName].type === "master") {
                if (value.material !== config[selectName].material) {
                  const [nextConfig, nextExtra] = getDefaultConfigWithExtra({
                    product,
                    material: value.material,
                  });
                  setConfig(nextConfig, nextExtra);
                  return;
                }
              }

              Cart.Actions.updateItem(itemIndex, {
                name: product.name,
                config: nextConfig,
              });
            }}
            side={config["side_select"]}
            viewOnly={viewOnly}
          />
        ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

export default ProductConfig;
