import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Cart from "../Contexts/CartContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > input {
    max-width: 180px;
  }
`;

const Button = styled.div`
  cursor: pointer;
  background-color: #a0a0a0;
  color: white;
  padding: 5px 10px;
  border-radius: 2px;

  :hover {
    background-color: grey;
  }
`;

class UploadAttachmentButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newFile: null,
      inputKey: Date.now(),
    };
  }

  render() {
    let { inputKey, newFile } = this.state;
    return (
      <Wrapper>
        <input
          key={inputKey}
          onClick={e => e.stopPropagation()}
          type="file"
          onChange={e => this.setState({ newFile: e.target.files[0] })}
        />
        {newFile && (
          <Button
            onClick={e => {
              e.stopPropagation();
              this._passFileToBackend();
            }}
          >
            上傳
          </Button>
        )}
      </Wrapper>
    );
  }

  _passFileToBackend = () => {
    let { newFile } = this.state;
    let { orderItem, updateOrderItem } = this.props;
    Cart.Actions.createAttachmentInstence({
      order: orderItem.order,
      item_index: orderItem.item_index,
      file_upload: newFile,
    })
      .then(updateOrderItem)
      .then(() => {
        this.setState({
          newFile: null,
          inputKey: Date.now(),
        });
        alert("檔案上傳成功!");
      })
      .catch(err => console.warn(err));
  };
}

export default UploadAttachmentButton;
