import React from "react";
import styled from "styled-components";
import * as Theme from "../Theme";

const DialogWrapper = styled.div`
  background-color: white;
  & > .title {
    font-size: 20px;
    color: ${Theme.colors.main};
    padding: 10px 25px;
    border-top: 6px solid ${Theme.colors.main};
    border-bottom: 1px solid #eaeaea;
    border-radius: 5px 5px 0px 0px;
    letter-spacing: 2px;
  }

  & > .subtitle {
    font-size: 20px;
    padding: 15px 30px;
    letter-spacing: 2px;
  }

  & > .content {
    padding: 31px 23px;
  }

  & > .border-content {
    padding: 30px 40px;
    border-bottom: 1px solid #eaeaea;
  }

  & .row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    & > p {
      font-size: 13px;
      margin: 0;
      color: #555555;
    }

    & > h4 {
      text-align: left;
      min-width: ${props => props.labelWidth || "100px"};
      font-size: 13px;
      font-weight: 400;
      color: #555555;
      margin-bottom: 0;
    }

    & > h5 {
      min-width: 90px;
      font-size: 12px;
      font-weight: 600;
      color: #19233b;
      margin-bottom: 0;
    }
  }

  & .block {
    background-color: #f5f5f5;
    padding: 8px 15px;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  ${props => props.css}
`;

const Input = styled.input`
  padding-left: 10px;
  width: 250px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #a6a6a6;

  :focus {
    outline: none;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid #a6a6a6;

  :focus {
    outline: none;
  }
`;

const Selector = styled.select`
  height: 30px;
  width: 250px;
  color: #19233b;
  background-color: #fff;

  :focus {
    outline: none;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: ${props => props.color || Theme.colors.main};
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;
  margin: 10px;

  ${props => props.css}
`;

const Title = styled.div`
  font-size: 18px;
  letter-spacing: 2px;
  color: #555555;
`;

export { DialogWrapper, Input, Selector, Button, TextArea, Title };
