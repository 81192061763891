import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
// import font from "../../static/SourceHanSansCN-Regular.ttf";
import PdfCartItemTable from "./PdfCartItemTable";

Font.register({
  family: "SourceHanSansCN",
  src: "https://static.revtel-api.com/common/SourceHanSansCN-Regular.ttf",
});

Font.registerHyphenationCallback(word => {
  if (word.length === 1) {
    return [word];
  }

  return Array.from(word)
    .map(char => [char, ""])
    .reduce((arr, current) => {
      arr.push(...current);
      return arr;
    }, []);
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "SourceHanSansCN",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 10,
  },
  image: {},
  title: {
    backgroundColor: "#c6e0b4",
    padding: 3,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 10,
  },

  textBig: {
    fontSize: 12,
  },

  form: {
    margin: 5,
    flexDirection: "column",
    borderWidth: 1,
    borderColor: "#b9b9b9",
  },
  firstRow: {
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f2f2f2",
    borderBottomColor: "#b9b9b9",
    borderBottomWidth: 1,
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#b9b9b9",
    borderBottomWidth: 1,
  },
  firstRowCell: {
    textAlign: "center",
    fontSize: 10,
    flex: 1,
    padding: 2,
    borderRightColor: "#b9b9b9",
    borderRightWidth: 1,
  },
  firstRowCellBig: {
    textAlign: "center",
    fontSize: 10,
    flex: 2,
    padding: 2,
    borderRightColor: "#b9b9b9",
    borderRightWidth: 1,
  },
  cell: {
    fontSize: 10,
    flex: 1,
    padding: 2,
    borderRightColor: "#b9b9b9",
    borderRightWidth: 1,
  },
  cellBig: {
    flexDirection: "column",
    fontSize: 10,
    flex: 2,
    padding: 2,
    borderRightColor: "#b9b9b9",
    borderRightWidth: 1,
  },
  cellMini: {
    fontSize: 10,
    flex: 1,
    padding: 1,
    borderBottomColor: "#b9b9b9",
    borderBottomWidth: 1,
  },
});

// Create Document Component
const MyDocument = props => {
  let { order, productSpec } = props;
  let cart = JSON.parse(order.cart);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.title}>
          <Text style={styles.textBig}>估價單(報價單)</Text>
        </View>

        <View style={styles.form}>
          <View style={styles.firstRow}>
            <Text style={styles.text}>工單資訊</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cell}>訂單編號：</Text>
            <Text style={styles.cellBig}>{order.display_id}</Text>
            <Text style={styles.cell}>統編抬頭：</Text>
            <Text style={styles.cellBig}></Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cell}>報價日期：</Text>
            <Text style={styles.cellBig}>
              {order.created.split("T")[0]} {order.created.slice(11, 16)}
            </Text>
            <Text style={styles.cell}>統一編號：</Text>
            <Text style={styles.cellBig}></Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cell}>客戶名稱：</Text>
            <Text style={styles.cellBig}>{cart.config.name}</Text>
            <Text style={styles.cell}>電話：</Text>
            <Text style={styles.cellBig}>{cart.config.phone}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cell}></Text>
            <Text style={styles.cellBig}></Text>
            <Text style={styles.cell}>地址：</Text>
            <Text style={styles.cellBig}>{cart.config.address}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cell}></Text>
            <Text style={styles.cellBig}></Text>
            <Text style={styles.cell}>信箱：</Text>
            <Text style={styles.cellBig}>{cart.config.email}</Text>
          </View>
        </View>

        <View style={styles.form}>
          <PdfCartItemTable cart={cart} productSpec={productSpec} />

          <View style={styles.row}>
            <Text
              style={{
                fontSize: 10,
                flex: 5,
                padding: 2,
              }}
            >
              商品小計
            </Text>
            <Text style={styles.cell}>{cart.calculations.original_amount}</Text>
          </View>
          <View style={styles.row}>
            <Text
              style={{
                fontSize: 10,
                flex: 5,
                padding: 2,
              }}
            >
              運費
            </Text>
            <Text style={styles.cell}>{cart.calculations.fee}</Text>
          </View>
          <View style={styles.row}>
            <Text
              style={{
                fontSize: 10,
                flex: 5,
                padding: 2,
              }}
            >
              活動折扣
            </Text>
            <Text style={styles.cell}>
              - {cart.calculations.discount_amount}
            </Text>
          </View>
          <View style={styles.row}>
            <Text
              style={{
                fontSize: 10,
                flex: 5,
                padding: 2,
              }}
            >
              總計(含稅)
            </Text>
            <Text style={styles.cell}>{cart.calculations.amount}</Text>
          </View>
        </View>

        <View style={styles.form}>
          <View style={styles.firstRow}>
            <Text style={styles.text}>估價單備註</Text>
          </View>
          <Text style={{ paddingLeft: 5, color: "grey", fontSize: 10 }}>
            {`
          1.本估價單有效期間7天。
          2.凡第一次交易之客戶，公司規定必須以現金或即期票付款。
          3.本報價單如貴公司簽署製作，視同訂購契約，中途如有更改或因故停止製作，本公司得依已製作部份收取製作費用。
          4.校稿與發案皆採用Mail、Line、傳真等系統文字為執行憑據。
          5.本報價單如蒙同意，則轉為買賣契約，金額無誤後煩請蓋章(簽)回傳。
          6.客戶提供之文字圖片等相關製作資料，保證絕無他人著作權，版權或涉及任何法律問題，如有涉及概由委製公司負責。
          7.客戶提供之檔案或稿件，經確認打樣付印後所產生之瑕疵問題，本公司概不負責。(客戶請仔細校對內容文字圖片)
          8.客戶提供完稿電子檔,報價含數位打樣1次
          9.以上含北縣市一處運費(其他縣市運費另計/實報實銷)，每筆交易未滿1000元，加收100元運費。
          `}
          </Text>
        </View>

        <View style={styles.form}>
          <View style={styles.firstRow}>
            <Text style={styles.text}>訂單簽認資訊</Text>
          </View>
          <View style={styles.row}>
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                borderRightColor: "#b9b9b9",
                borderRightWidth: 1,
              }}
            >
              <Text style={styles.text}>客戶簽認(大小章)：</Text>
              <View style={{ flex: 1 }}></View>
              <Text style={styles.text}>客戶代表：</Text>
              <Text style={styles.text}>統一編號：</Text>
              <Text style={styles.text}>聯絡人：</Text>
              <Text style={styles.text}>聯絡電話：</Text>
            </View>
            <View style={{ flex: 1, flexDirection: "column" }}>
              <Text style={styles.cellMini}>理想印制有限公司</Text>
              <Text style={styles.cellMini}>統一編號：83572519</Text>
              <Text style={styles.cellMini}>聯絡電話：02-2736-7701</Text>
              <Text style={styles.cellMini}>
                電子郵件：service@lixiangprint.com.tw
              </Text>
            </View>
          </View>
          <Text style={styles.cell}>
            估價單日期：{order.created.slice(0, 16).replace("T", " ")}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const QuotationFormPdfDownload = props => {
  return (
    <div>
      <PDFDownloadLink
        style={props.style}
        document={<MyDocument {...props} />}
        fileName="估價單.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "下載中..." : "下載估價單"
        }
      </PDFDownloadLink>
    </div>
  );
};

export { QuotationFormPdfDownload };
