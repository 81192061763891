import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { connect } from "react-redux";
import * as Theme from "../Theme";

const PaymentTypes = [
  {
    label: "ATM付款",
    img: "../../images/payment_web_atm.svg",
    value: "atm",
    intro: `1.使用線上ATM付款的用戶每一筆訂單會產生不同的銀行匯款帳號，請於七日內完成匯款，付款完成後系統將於五分鐘後顯示付款結果。
2.繳款金額不符或者逾時匯款，銀行系統將無法接受匯款。本網站採用先收款後製作，請在收到付款通知後七日內完成匯款。
3.若已過匯款期限，訂單將自動取消，若需再次交易，請重新下單。`,
    iconImg: "",
  },
  {
    label: "信用卡或銀聯卡付款",
    img: "../../images/payment_credit.svg",
    value: "credit",
    intro: `1. 請輸入以上信用卡資料、以便於向發卡行取得交易授權。完成交易授權只是發卡行確認信用卡的有效性及授權交易額度，您的信用卡信用額度在交易金額範圍內會被保留，但不代表您的付款已經完成，這筆交易也還不會出現在您的信用卡帳單中。
2. 如果您所訂購的商品仍有存貨、且交易條件經確認無誤，我們才會向發卡行請款，只有當我們收到信用卡交易款項時，您的付款程序才算完成。
3. 為確保網路交易安全，您同意本公司得就此筆交易向發卡銀行、收單行及持卡人核對是否屬實；您所填寫的身份證字號、出生年月日資料，亦將於核對完成後刪除。`,
    iconImg: "",
  },
  //   {
  //     label: "機構臨櫃匯款",
  //     img: "../../images/payment_atm.svg",
  //     value: "atm",
  //     intro: `1.限企業用戶使用，匯款金額需與訂單金額相符，匯款完成後請於「 我的訂單 」輸入匯款人名稱及匯出帳號之後五碼，以利核對月結客戶付款定為當月消費、次月付款，發票統一於消費次月5日前開立，客戶需於次月25日前電匯貨款至本公司帳戶。
  // 2.如客戶未匯款或匯款不足額，則停止其月結資格，待處理完成後始得恢復。`,
  //     iconImg: "",
  //   },
  //   {
  //     label: "超商代碼繳費",
  //     img: "../../images/payment_cvs.svg",
  //     value: "cvs",
  //     intro: `1. 本網站與智付通合作，點選超商代碼繳費後，系統會產生付款條碼，可攜帶條碼至7-11、全家、萊爾富、OK等超商進行付款。
  // 2. 本網站採用先收款後製作，請在收到付款通知後七日內完成匯款，若已過匯款期限，訂單將自動取消，若需再次交易，請重新下單。
  // 3. 30元(含)以上～20,000元(含)以下的訂單，都可以使用超商代碼繳費。超商代碼繳費會產生28元交易手續費，交易完成後若需退款，手續費無法退還。`,
  //     iconImg: "",
  //   },
];

class PaymentTypeOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: PaymentTypes[0].value,
    };
  }

  componentDidMount() {
    let { editCheckoutParams } = this.props;
    editCheckoutParams(this.state.selectedOption);
  }

  render() {
    let { selectedOption } = this.state;
    let { editCheckoutParams, profile, cartData } = this.props;

    return (
      <Wrapper>
        {PaymentTypes.filter(option => {
          if (cartData && cartData.calculations.amount < 30) {
            // < $30 order can't be 超商代碼繳費 cvs
            return option.value !== "cvs";
          }
          return true;
        }).map(option => {
          return (
            <>
              <OptionItem
                onChange={e => {
                  this.setState({ selectedOption: e.target.value });
                  editCheckoutParams(e.target.value);
                }}
                selected={selectedOption === option.value}
                value={selectedOption}
              >
                <RadioBtn
                  value={option.value}
                  checked={selectedOption === option.value}
                />
                <Title selected={selectedOption === option.value}>
                  {option.label}
                </Title>
              </OptionItem>
              <div
                style={{ padding: 20, display: "flex", alignItems: "center" }}
              >
                <img src={option.img} width="100" />
                <Content>{option.intro}</Content>
              </div>
            </>
          );
        })}
        {cartData.calculations.amount < 30 && (
          <Ant.Alert message={"超商付款需滿30元"} type="info" />
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

const OptionItem = styled(Ant.Radio.Group)`
  color: ${props => (props.selected ? Theme.colors.main : "#717071")};
  display: flex;
`;
const Title = styled.p`
  color: ${props => (props.selected ? Theme.colors.main : "#717071")};
`;

const Content = styled.p`
  margin: 0px;
  white-space: pre-wrap;
  flex: 1;
  font-size: 13px;
  line-height: 24px;
  color: ${props => (props.selected ? Theme.colors.main : "#717071")};
`;

const RadioBtn = styled(Ant.Radio)`
  .ant-radio-checked .ant-radio-inner {
    color: ${Theme.colors.main};
    border-color: ${Theme.colors.main};
  }
  .ant-radio-inner::after {
    background-color: ${Theme.colors.main};
    color: ${Theme.colors.main};
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: ${Theme.colors.main};
    color: ${Theme.colors.main};
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(PaymentTypeOptions);
