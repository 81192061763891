import React, { Fragment } from "react";
import {
  DialogWrapper,
  Input,
  Button,
  Selector,
  TextArea,
  Title,
} from "./DialogWidgets";
import * as Icon from "./Icon";
import * as Ant from "antd";

class TermsDialog extends React.Component {
  render() {
    let { closeDialog } = this.props;

    return (
      <Ant.Modal
        visible={true}
        title={<Title>服務條款聲明</Title>}
        footer={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button css="margin: 10px;" onClick={() => closeDialog()}>
              確定
            </Button>
          </div>
        }
        closeIcon={
          <Icon.Close
            style={{ position: "absolute", right: 20, top: 20 }}
            size={20}
          />
        }
        onCancel={closeDialog}
        style={{
          textAlign: "center",
        }}
        width="80%"
      >
        <DialogWrapper>
          <div
            className="content"
            style={{ textAlign: "left", whiteSpace: "pre-wrap" }}
          >
            <p>{`非常歡迎您光臨「」（以下簡稱本網站），
為了讓您能夠安心的使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，
以保障您的權益，請您詳閱下列內容：
`}</p>
            <h4>個人資料之安全</h4>
            <p>123</p>
          </div>
        </DialogWrapper>
      </Ant.Modal>
    );
  }
}

export default TermsDialog;
