import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import DropdownSelector from "./DropdownSelector";
import * as Catalog from "../Contexts/CatalogContext";

function OptionBtn({ label, selected, onClick }) {
  const selectedProps = selected ? { type: "primary" } : {};
  return (
    <Ant.Button
      style={{ marginRight: 8, marginBottom: 8 }}
      onClick={onClick}
      {...selectedProps}
    >
      {label}
    </Ant.Button>
  );
}

function isRangeOptionSelected(num, option) {
  return (
    num && Number(option.range_from) <= num && num <= Number(option.range_to)
  );
}

function parseMasterOption(option) {
  return {
    ...option,
    quantityOptions: option.range.split(",").map(value => {
      let [from, to] = value.split("-");
      return {
        range_from: parseInt(from),
        range_to: parseInt(to),
      };
    }),
    sizeOptions: option.support_size.split(","),
  };
}

function findExtraDisplayInfo(param, value, config) {
  const options = param.options;
  const extra = {};

  if (options && Array.isArray(options)) {
    let selectedOption = null;
    if (param.type === "single") {
      selectedOption = options.find(opt => opt.name === value);
    } else if (param.type === "master") {
      selectedOption = options.find(opt => opt.name === value.name);
    } else if (param.type === "group-number") {
      selectedOption = options.find(opt => opt.name === value.name);
    }

    if (selectedOption) {
      if (selectedOption.open_template_day) {
        extra.open_template_day = selectedOption.open_template_day;
      } else if (selectedOption.original_price) {
        extra.original_price = selectedOption.original_price;
      }

      if (
        config.side_select === "single" &&
        selectedOption.single_original_price
      ) {
        extra.original_price = selectedOption.single_original_price;
      } else if (
        config.side_select === "double" &&
        selectedOption.double_original_price
      ) {
        extra.original_price = selectedOption.double_original_price;
      }
    }
  }

  return extra;
}

function getDefaultConfigWithExtra({ product, side, material }) {
  const selectNames = Catalog.Actions.getProductSelectNames(product);
  const defaultValues = {};
  let extra = {};
  let currSelectName = null;
  try {
    if (side === undefined) {
      side = null;
      if (selectNames.indexOf("side_select") > -1) {
        defaultValues["side_select"] = product.side_select.options[0].name;
      }
    }

    for (let selectName of selectNames) {
      if (selectName === "side_select") {
        continue;
      }

      currSelectName = selectName;
      const param = filterParamOptionsWithSide({
        side,
        param: product[selectName],
      });

      if (param.type === "single") {
        defaultValues[selectName] = param.options[0].name;
      } else if (param.type === "multiple") {
        defaultValues[selectName] = [];
      } else if (param.type === "number") {
        defaultValues[selectName] = Number(param.options[0].range_from);
      } else if (param.type === "group-number") {
        defaultValues[selectName] = {
          name: param.options[0].name,
          quantity: Number(param.options[0].range_from),
        };
      } else if (param.type === "master") {
        const selectedMasterOption =
          param.options.find(opt => opt.material === material) ||
          param.options[0];
        const masterSelect = parseMasterOption(selectedMasterOption);
        defaultValues[selectName] = {
          material: masterSelect.material,
          quantity: masterSelect.quantityOptions[0].range_from,
          size: masterSelect.sizeOptions[0],
        };
      } else {
        throw "no param type";
      }

      const nextExtra = findExtraDisplayInfo(
        param,
        defaultValues[selectName],
        defaultValues
      );

      extra = {
        ...nextExtra,
        ...extra,
      };
    }
  } catch (ex) {
    if (typeof window === "object") {
      alert(`Spec Error for ${product.name}:${currSelectName}`);
    }
  }

  return [defaultValues, extra];
}

function filterParamOptionsWithSide({ side, param }) {
  if (!side) {
    return param;
  }

  let options = param.options;
  if (side === "single") {
    options = param.options.filter(option => {
      if (typeof option.support_single === "boolean") {
        return option.support_single;
      }
      return true;
    });
  } else if (side === "double") {
    options = param.options.filter(option => {
      if (typeof option.support_double === "boolean") {
        return option.support_double;
      }
      return true;
    });
  }

  return {
    ...param,
    options,
  };
}

class ProductParam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: this._getDefaultInputValue(),
    };
  }

  render() {
    let { param, viewOnly } = this.props;

    if (viewOnly) {
      return <ParamWrapper>{this._renderViewOnly()}</ParamWrapper>;
    }

    return (
      <ParamWrapper>
        {param.type === "single" && this._renderSingle()}
        {param.type === "multiple" && this._renderMultiple()}
        {param.type === "number" && this._renderNumber()}
        {param.type === "group-number" && this._renderQuantity()}
        {param.type === "master" && this._renderMaster()}
      </ParamWrapper>
    );
  }

  _getDefaultInputValue = () => {
    let { param, value } = this.props;
    if (param.type === "number") {
      return value;
    } else if (param.type === "group-number") {
      return value.quantity;
    } else if (param.type === "master") {
      return value.quantity;
    }
  };

  _renderViewOnly = () => {
    let { selectName, param, value } = this.props;

    param = filterParamOptionsWithSide({ param, side: this.props.side });

    if (param.type === "group-number") {
      return (
        <SelectRow>
          <label>{param.label || selectName}</label>
          <div className="select-options">{`${value.name}-${value.quantity}`}</div>
        </SelectRow>
      );
    } else if (param.type === "master") {
      return (
        <>
          <SelectRow>
            <label>{param.label || selectName}</label>
            <div className="select-options">{`${value.material}`}</div>
          </SelectRow>
          <SelectRow>
            <label>尺寸</label>
            <div className="select-options">{`${value.size}`}</div>
          </SelectRow>
          <SelectRow>
            <label>數量</label>
            <div className="select-options">{`${value.quantity}`}</div>
          </SelectRow>
        </>
      );
    } else if (param.type === "single") {
      const selectedOption = param.options.find(opt => opt.name === value);
      if (selectedOption) {
        return (
          <SelectRow>
            <label>{param.label || selectName}</label>
            <div className="select-options">
              {selectedOption.label || value}
            </div>
          </SelectRow>
        );
      }
    } else if (param.type === "multiple") {
      const allSelections = value.reduce((v, acc) => {
        const selectedOption = param.options.find(opt => opt.name === v);
        const selectedLabel = (selectedOption && selectedOption.label) || v;
        return `${acc}, ${selectedLabel}`;
      }, "");

      return (
        <SelectRow>
          <label>{param.label || selectName}</label>
          <div className="select-options">{allSelections}</div>
        </SelectRow>
      );
    }

    return (
      <SelectRow>
        <label>{param.label || selectName}</label>
        <div className="select-options">{value}</div>
      </SelectRow>
    );
  };

  _renderMaster = () => {
    let { selectName, param, value, setValue } = this.props;

    param = filterParamOptionsWithSide({ param, side: this.props.side });

    const displayMap = {};

    for (const option of param.options) {
      displayMap[option.material] = option.label || option.material;
    }

    const selectedMasterOption = parseMasterOption(
      param.options.find(o => o.material === value.material)
    );

    return (
      <>
        <SelectRow css="margin-bottom: 20px;">
          <label>{param.label || selectName}</label>

          <div className="select-options">
            <DropdownSelector
              optionsArr={param.options.map(option => option.material)}
              onChoiceClick={choice => setValue({ ...value, material: choice })}
              selectedOption={value.material}
              displayMap={displayMap}
            />
          </div>
        </SelectRow>

        <SelectRow css="margin-bottom: 20px;">
          <label>尺寸</label>

          <div className="select-options">
            <DropdownSelector
              optionsArr={selectedMasterOption.sizeOptions}
              onChoiceClick={choice => setValue({ ...value, size: choice })}
              selectedOption={value.size}
            />
          </div>
        </SelectRow>

        <SelectRow>
          <label>數量</label>

          <div className="select-options">
            {selectedMasterOption.quantityOptions.map((option, idx) => {
              if (option.range_from === option.range_to) {
                return (
                  <OptionBtn
                    key={idx}
                    label={option.range_from}
                    selected={value.quantity === Number(option.range_from)}
                    onClick={() => {
                      this.setState({ inputValue: null });
                      setValue({
                        ...value,
                        quantity: Number(option.range_from),
                      });
                    }}
                  />
                );
              }

              const selected = isRangeOptionSelected(value.quantity, option);
              const min = Number(option.range_from);
              const max = Number(option.range_to);
              const inputStyle = selected
                ? {
                    color: "#1890FF",
                    borderColor: "#1890FF",
                  }
                : {
                    color: "gray",
                  };

              return (
                <div key={idx} style={{ margin: 5, display: "inline-block" }}>
                  <span style={{ fontSize: 10, marginRight: 5 }}>自訂</span>
                  <Ant.Input
                    key={idx}
                    type="number"
                    value={this.state.inputValue}
                    style={{ maxWidth: 80, ...inputStyle }}
                    onChange={e => {
                      this.setState({ inputValue: e.target.value });
                    }}
                    onBlur={() => {
                      if (this.state.inputValue) {
                        const nextValue = Number(this.state.inputValue);
                        if (min <= nextValue && nextValue <= max) {
                          setValue({
                            ...value,
                            quantity: nextValue,
                          });
                        } else {
                          if (nextValue > max) {
                            alert(
                              `該需求為特殊規格或數量，請來電洽詢或加入官方LINE專人詢價。`
                            );
                          } else {
                            alert(`注意: 可用的自訂範圍介於${min}~${max}!`);
                          }

                          this.setState({ inputValue: min });
                          setValue({
                            ...value,
                            quantity: min,
                          });
                        }
                        return;
                      }

                      this.setState({ inputValue: null });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </SelectRow>
      </>
    );
  };

  _renderSingle = () => {
    let { selectName, param, value, setValue } = this.props;

    param = filterParamOptionsWithSide({ param, side: this.props.side });

    const displayMap = {};

    for (const option of param.options) {
      displayMap[option.name] = option.label || option.name;
    }

    return (
      <SelectRow>
        <label>{param.label || selectName}</label>

        <div className="select-options">
          <DropdownSelector
            optionsArr={param.options.map(option => option.name)}
            onChoiceClick={choice => setValue(choice)}
            selectedOption={value}
            displayMap={displayMap}
          />
        </div>
      </SelectRow>
    );
  };

  _renderMultiple = () => {
    let { selectName, param, value, setValue } = this.props;

    param = filterParamOptionsWithSide({ param, side: this.props.side });

    const displayMap = {};

    for (const option of param.options) {
      displayMap[option.name] = option.label || option.name;
    }

    return (
      <SelectRow>
        <label>{param.label || selectName}</label>

        <div className="select-options">
          <DropdownSelector
            multiple={true}
            optionsArr={param.options.map(option => option.name)}
            multiSelectedArr={value}
            onChoiceClick={choice => {
              const selected = value && value.indexOf(choice) > -1;

              if (!value || !Array.isArray(value)) {
                value = [];
              } else {
                value = [...value];
              }

              if (!selected) {
                value.push(choice);
              } else {
                value.splice(value.indexOf(choice), 1);
              }

              setValue(value);
            }}
            displayMap={displayMap}
          />
        </div>
      </SelectRow>
    );
  };

  _renderNumber = () => {
    let { selectName, param, value, setValue } = this.props;

    param = filterParamOptionsWithSide({ param, side: this.props.side });

    return (
      <SelectRow>
        <label>{param.label || selectName}</label>

        <div className="select-options">
          {param.options.map((option, idx) => {
            if (option.range_from === option.range_to) {
              return (
                <OptionBtn
                  key={idx}
                  label={option.range_from}
                  selected={value === Number(option.range_from)}
                  onClick={() => {
                    this.setState({ inputValue: null });
                    setValue(Number(option.range_from));
                  }}
                />
              );
            }

            const selected = isRangeOptionSelected(value, option);
            const min = Number(option.range_from);
            const max = Number(option.range_to);
            const inputStyle = selected
              ? {
                  color: "#1890FF",
                  borderColor: "#1890FF",
                }
              : {
                  color: "gray",
                };

            return (
              <div key={idx} style={{ margin: 5, display: "inline-block" }}>
                <span style={{ fontSize: 10, marginRight: 5 }}>自訂</span>
                <Ant.Input
                  key={idx}
                  type="number"
                  value={this.state.inputValue}
                  style={{ maxWidth: 80, ...inputStyle }}
                  onChange={e => {
                    this.setState({ inputValue: e.target.value });
                  }}
                  onBlur={() => {
                    if (this.state.inputValue) {
                      const nextValue = Number(this.state.inputValue);
                      if (min <= nextValue && nextValue <= max) {
                        setValue(nextValue);
                      } else {
                        if (nextValue > max) {
                          alert(
                            `該需求為特殊規格或數量，請來電洽詢或加入官方LINE專人詢價。`
                          );
                        } else {
                          alert(`注意: 可用的自訂範圍介於${min}~${max}!`);
                        }

                        this.setState({ inputValue: min });
                        setValue(min);
                      }
                      return;
                    }

                    this.setState({ inputValue: null });
                  }}
                />
              </div>
            );
          })}
        </div>
      </SelectRow>
    );
  };

  _renderQuantity = () => {
    let { selectName, param, value, setValue } = this.props;
    let groups = {};

    param = filterParamOptionsWithSide({ param, side: this.props.side });

    for (let option of param.options) {
      groups[option.name] = true;
    }

    groups = Object.keys(groups);

    const { name: groupName, quantity } = value;
    const subOptions = param.options.filter(
      option => option.name === groupName
    );

    return (
      <>
        <SelectRow>
          <label>商品材質</label>

          <div className="select-options">
            <DropdownSelector
              optionsArr={groups}
              onChoiceClick={choice => {
                if (choice !== groupName) {
                  setValue({
                    name: choice,
                    quantity: Number(
                      param.options.find(option => option.name === choice)
                        .range_from
                    ),
                  });
                }
              }}
              selectedOption={value.name}
            />
          </div>
        </SelectRow>

        <SelectRow css="margin-top: 20px;">
          <label>{param.label || selectName}</label>

          <div className="select-options">
            {subOptions.map((option, idx) => {
              if (option.range_from === option.range_to) {
                return (
                  <OptionBtn
                    key={`${groupName}-${idx}`}
                    label={option.range_from}
                    selected={quantity === Number(option.range_from)}
                    onClick={() => {
                      this.setState({ inputValue: null });
                      setValue({
                        name: groupName,
                        quantity: Number(option.range_from),
                      });
                    }}
                  />
                );
              }

              const selected = isRangeOptionSelected(quantity, option);
              const min = Number(option.range_from);
              const max = Number(option.range_to);
              const inputStyle = selected
                ? {
                    color: "#1890FF",
                    borderColor: "#1890FF",
                  }
                : {
                    color: "gray",
                  };

              return (
                <div key={idx} style={{ margin: 5, display: "inline-block" }}>
                  <span style={{ fontSize: 10, marginRight: 5 }}>自訂</span>
                  <Ant.Input
                    key={idx}
                    type="number"
                    value={this.state.inputValue}
                    style={{ maxWidth: 80, ...inputStyle }}
                    onChange={e => {
                      this.setState({ inputValue: e.target.value });
                    }}
                    onBlur={() => {
                      if (this.state.inputValue) {
                        const nextValue = Number(this.state.inputValue);
                        if (min <= nextValue && nextValue <= max) {
                          setValue({
                            name: groupName,
                            quantity: nextValue,
                          });
                        } else {
                          if (nextValue > max) {
                            alert(
                              `該需求為特殊規格或數量，請來電洽詢或加入官方LINE專人詢價。`
                            );
                          } else {
                            alert(`注意: 可用的自訂範圍介於${min}~${max}!`);
                          }

                          this.setState({ inputValue: min });
                          setValue({
                            name: groupName,
                            quantity: min,
                          });
                        }
                        return;
                      }

                      this.setState({ inputValue: null });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </SelectRow>
      </>
    );
  };
}

const ParamWrapper = styled.div`
  padding: 10px 0px;
  & label {
    font-weight: bold;
  }
`;

const SelectRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > label {
    flex-basis: 100px;
  }

  & > .select-options {
    flex: 1;
    min-width: 150px;
  }

  ${props => props.css || ""}
`;

export default ProductParam;
export { findExtraDisplayInfo, getDefaultConfigWithExtra };
