import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import Expander from "./Expander";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { connect } from "react-redux";
import OrderTotalAmount from "../Components/OrderTotalAmount";
import OrderHintBlock from "../Components/OrderHintBlock";
import * as Theme from "../Theme";
import { isVIP, isMonthly } from "../Domain/RoleValidator";
import {
  isPersonCertificatedCode,
  isMobileVehicleCode,
  isCompanyId,
  isNotEmpty,
  isLoveCode,
} from "../Domain/FormatValidator";
const validator = require("validator");

export const CATEGORIES = {
  two_copies: {
    idx: 0,
    label: "二聯式發票",
    value: "B2C",
  },
  three_copies: {
    idx: 1,
    label: "三聯式發票",
    value: "B2B",
  },
};

export const CARRIER_TYPE = {
  npc: {
    idx: 0,
    label: "自然人憑證條碼",
    value: "npc",
  },
  mobile: {
    idx: 1,
    label: "手機載具",
    value: "mobile",
  },
  default: {
    idx: 2,
    label: "ezPay 電子發票載具",
    value: "default",
  },
  donation: {
    idx: 3,
    label: "捐贈發票",
    value: "donation",
  },
};

class InvoiceForm extends React.Component {
  render() {
    const { setConfig, config, showHint } = this.props;
    let { category, uni_no, company_title, carrier_type, carrier_num } = config;
    let carrier =
      category === CATEGORIES.two_copies.value
        ? CARRIER_TYPE[carrier_type]
        : null;
    console.log(category, carrier_type);

    return (
      <Wrapper>
        <Ant.Radio.Group
          value={category}
          onChange={e => {
            setConfig({ category: e.target.value });
          }}
        >
          <RadioBtn value={CATEGORIES.two_copies.value}>
            二聯式電子發票
          </RadioBtn>
          {category === CATEGORIES.two_copies.value && (
            <Content>
              <Row>
                <Label>發票類型</Label>
                <Select
                  value={carrier_type}
                  onChange={value => {
                    setConfig({ carrier_type: value });
                  }}
                >
                  {Object.values(CARRIER_TYPE)
                    // .filter(carr => carr.value === "default")
                    .map(carr => (
                      <Option value={carr.value} key={carr.idx}>
                        {carr.label}
                      </Option>
                    ))}
                </Select>
              </Row>

              <Row>
                {carrier && carrier.value !== CARRIER_TYPE.default.value && (
                  <>
                    <Label>{carrier.label}</Label>
                    <TextInput
                      placeholder={
                        {
                          mobile:
                            "請輸入手機載具條碼8碼(斜線(/)加上7碼數字或大寫字母)",
                          npc: "請輸入自然人憑證條碼格式（2碼英文 + 14碼數字）",
                          donation: "請輸入愛心碼格式（3~7個數字）",
                          default: "",
                        }[carrier.value]
                      }
                      value={carrier_num}
                      onChange={e => {
                        setConfig({ carrier_num: e.target.value });
                      }}
                    />
                  </>
                )}
              </Row>
            </Content>
          )}

          <RadioBtn value={CATEGORIES.three_copies.value}>
            三聯式電子發票
          </RadioBtn>
          {category === CATEGORIES.three_copies.value && (
            <Content>
              <Row>
                <Label>統一編號</Label>
                <TextInput
                  placeholder="請輸入公司統編"
                  value={uni_no}
                  onChange={e => setConfig({ uni_no: e.target.value })}
                />
              </Row>

              <Row>
                <Label>公司抬頭</Label>
                <TextInput
                  placeholder="請輸入公司抬頭"
                  value={company_title}
                  onChange={e => setConfig({ company_title: e.target.value })}
                />
              </Row>
            </Content>
          )}
        </Ant.Radio.Group>

        {showHint && (
          <Ant.Alert
            message="1. 個人(二聯式)發票一經開立，無法更改或改開公司戶(三聯式)發票。 2. 根據財政部「電子發票實施作業要點」，於消費開立電子發票不主動寄送，會將發票號碼上傳至政府平台。"
            style={{ marginTop: 10 }}
          />
        )}
      </Wrapper>
    );
  }
}

const ErrorHint = ({ message }) => {
  return <Alert message={message} type="warning" showIcon />;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

const Alert = styled(Ant.Alert)`
  .ant-alert-message {
    color: #6b6b6b;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  ${props => props.css}
`;

const Content = styled.div`
  margin: 10px 8px;
  padding: 10px;
  border-left: 1px solid #ddd;
`;

const TextInput = styled(Ant.Input)`
  margin: 5px;
  flex-basis: 400px;
  ${props => (props.type === "short" ? "flex-basis: 90px; width: 90px;" : "")}
  ${props => (props.type === "long" ? "flex-basis: 320px;" : "")}

  :hover {
    border-color: ${Theme.colors.main}4;
  }

  :focus {
    border-color: ${Theme.colors.main};
    outline-color: ${Theme.colors.main}4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }

  .ant-input::selection {
    border-color: green;
  }

  /* ::after {
    border-color: green;
    background: green;
} */
`;

const Select = styled(Ant.Select)`
  margin: 5px;
  flex-basis: 200px;
`;

const Option = styled(Ant.Select.Option)`
  width: 200px;
`;

const Label = styled.label`
  margin-right: 10px;
  flex-basis: 60px;
  flex-shrink: 0;
`;

const RadioBtn = styled(Ant.Radio)`
  display: block;
  margin-top: 10px;
  .ant-radio-checked .ant-radio-inner {
    color: ${Theme.colors.main};
    border-color: ${Theme.colors.main};
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
  .ant-radio-inner::after {
    background-color: ${Theme.colors.main};
    color: ${Theme.colors.main};
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: ${Theme.colors.main};
    color: ${Theme.colors.main};
  }
`;

const Checkbox = styled(Ant.Checkbox)`
  /* TODO: how to change hover border color when checked ? */
  .ant-checkbox-checked :hover {
    /* it isn't work */
    border-color: ${Theme.colors.main};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Theme.colors.main};
    border-color: ${Theme.colors.main};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${Theme.colors.main};
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
`;

export function valid(config) {
  // invoice config
  let { category, uni_no, company_title, carrier_type, carrier_num } = config;

  if (category === CATEGORIES.two_copies.value) {
    if (carrier_type === CARRIER_TYPE.npc.value) {
      if (!isNotEmpty(carrier_num) || !isPersonCertificatedCode(carrier_num)) {
        Ant.message.error("二聯式發票/自然人憑證格式錯誤");
        throw Error;
      }
    } else if (carrier_type === CARRIER_TYPE.mobile.value) {
      if (!isNotEmpty(carrier_num) || !isMobileVehicleCode(carrier_num)) {
        Ant.message.error("二聯式發票/手機載具格式錯誤");
        throw Error;
      }
    } else if (carrier_type === CARRIER_TYPE.donation.value) {
      if (!isNotEmpty(carrier_num) || !isLoveCode(carrier_num)) {
        Ant.message.error("捐贈發票格式錯誤");
        throw Error;
      }
    }
  }

  if (category === CATEGORIES.three_copies.value) {
    if (
      !isNotEmpty(uni_no) ||
      !isCompanyId(uni_no) ||
      !isNotEmpty(company_title)
    ) {
      Ant.message.error("三聯式發票格式錯誤");
      throw Error;
    }
  }
}

export function parseInvoice({ order, cart }) {
  let result = [];
  try {
    if (order && order.invoice_transaction_detail_1) {
      //目前這邊不使用，如果要拿發票產生後的資料要再打api拿發票
      let invoiceData = JSON.parse(order.invoice_transaction_detail_1);
      if (invoiceData.request_data.Category === "B2C") {
        result.push({
          label: "發票種類",
          value: CATEGORIES.two_copies.label,
        });

        result.push({
          label: "載具類別",
          value: {
            0: "手機條碼載具",
            1: "自然人憑證條碼載具",
            2: "ecPay 電子發票載具",
          }[invoiceData.request_data.CarrierType],
        });
      }
      if (invoiceData.request_data.Category === "B2B") {
        result.push({
          label: "發票種類",
          value: CATEGORIES.three_copies.label,
        });
        result.push({
          label: "統一編號",
          value: invoiceData.request_data.BuyerUBN,
        });
      }
    }

    //發票尚未產生 (greenjump目前always display user config 而非真實發票回來的資料)
    if (cart) {
      let {
        category,
        uni_no,
        company_title,
        carrier_type,
        carrier_num,
      } = cart.config;

      switch (category) {
        case CATEGORIES.three_copies.value:
          result.push({
            label: "發票種類",
            value: CATEGORIES.three_copies.label,
          });
          result.push({ label: "公司統編", value: uni_no });
          result.push({
            label: "公司抬頭",
            value: company_title,
          });

          break;
        case CATEGORIES.two_copies.value:
          result.push({
            label: "發票種類",
            value: CATEGORIES.two_copies.label,
          });
          let carrier = CARRIER_TYPE[carrier_type] || null;
          if (carrier) {
            result.push({
              label: "二聯發票種類",
              value: carrier.label,
            });
            if (carrier.value !== CARRIER_TYPE.default.value) {
              result.push({
                label: carrier.label,
                value: carrier_num,
              });
            }
          }
          break;

        default:
          break;
      }
    }
  } catch (err) {
    result.push({ label: "解析發票錯誤", value: "" });
    console.warn(err);
  }
  return result;
}

export default InvoiceForm;
