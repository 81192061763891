import React, { Component } from "react";
import styled from "styled-components";

class DropdownSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this._isClickOutside = true;
  }

  componentDidMount() {
    try {
      // prevent Reference Error: document in server side
      // true -> listener get the event first, false -> onClick attach get the event first
      document.addEventListener("click", this._mouseClick, false);
    } catch (err) {
      //
    }
  }

  componentWillUnmount() {
    try {
      document.removeEventListener("click", this._mouseClick, false);
    } catch (err) {
      //
    }
  }

  render() {
    let {
      appActions,
      css = "",
      optionsArr,
      selectedOption,
      onChoiceClick,
      displayMap,
      /* 
      pass displayMap if need translate 
      format-->
       {
        single: "單面",
        double: "雙面"
       }
      */

      /* for multiple selector */
      multiple = false,
      multiSelectedArr,
    } = this.props;
    let { isOpen } = this.state;
    return (
      <Wrapper css={css} isOpen={isOpen}>
        <div className="row">
          <div
            className="button-wrapper"
            onClick={() => {
              this._isClickOutside = false;
              this.setState({ isOpen: !isOpen });
            }}
          >
            <p>
              {(() => {
                if (multiple) {
                  return `選擇多選選項`;
                } else {
                  return displayMap
                    ? displayMap[`${selectedOption}`]
                    : selectedOption;
                }
              })()}
            </p>
            <div className="arrow-down-wrapper">
              <div className="arrow-down" />
            </div>
          </div>
          <div className="toggle-menu">
            {optionsArr.map((o, idx) => (
              <div
                className={
                  "menu-item" + (o === selectedOption ? " active" : "")
                }
                key={idx}
                onClick={e => {
                  this._isClickOutside = false;
                  this.setState({ isOpen: false }, () => onChoiceClick(o, idx));
                }}
              >
                {(() => {
                  if (multiple && Array.isArray(multiSelectedArr)) {
                    return multiSelectedArr.indexOf(idx) >= 0 ? (
                      <div className="dot" />
                    ) : (
                      <div className="empty-dot" />
                    );
                  }
                })()}
                {displayMap ? displayMap[`${o}`] : o}
              </div>
            ))}
          </div>
        </div>
        {multiple &&
          multiSelectedArr &&
          multiSelectedArr.map(i => (
            <div className="multiple-selected-item">
              <p>{i}</p>
            </div>
          ))}
      </Wrapper>
    );
  }
  _mouseClick = e => {
    if (!this._isClickOutside) {
      this._isClickOutside = true;
      return;
    }
    this.setState({ isOpen: false });
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & .row {
    position: relative;
    & .button-wrapper {
      cursor: pointer;
      padding-left: 10px;
      border: 1px solid #cccccc;
      height: 40px;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > .arrow-down-wrapper {
        display: flex;
        border-left: 1px solid #cccccc;
        width: 35px;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        & > .arrow-down {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 5px 0 5px;
          border-color: #cccccc transparent transparent transparent;
        }
      }
      & > p {
        color: #4a4a4a;
        font-size: 14px;
        line-height: 17px;
        padding-top: 13px;
      }
    }
    & .toggle-menu {
      z-index: 1;
      position: absolute;
      background-color: white;
      left: 0px;
      top: 35px; /* button height */
      padding: 20px 0px;
      width: 100%;
      border: 1px solid #cccccc;
      box-shadow: -2px -2px 7px 1px rgba(132, 132, 132, 0.06);
      /* animation */
      transition: all 0.2s;
      pointer-events: ${props => (props.isOpen ? "all" : "none")};
      opacity: ${props => (props.isOpen ? 1 : 0)};
      top: ${props => (props.isOpen ? "100%" : "20%")};
      & .menu-item {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #4e4e4e;
        background-color: white;
        text-transform: uppercase;
        cursor: pointer;
        padding: 4px 20px;
        display: flex;
        align-items: center;
        background-color: #fff;
        transition: background-color 0.3s;
        &:hover {
          background-color: rgba(200, 200, 200, 0.5);
        }
        & .empty-dot {
          margin-right: 5px;
          width: 14px;
          height: 14px;
          border-radius: 7px;
          background-color: #fff;
          border: 2px solid #888888;
        }
        & .dot {
          margin-right: 5px;
          width: 14px;
          height: 14px;
          border-radius: 7px;
          background-color: #888888;
          border: 2px solid #888888;
        }
      }
      & .menu-item.active {
        color: black;
      }
    }
  }

  & .multiple-selected-item {
    display: flex;
    background-color: #eeeeef;
    padding: 5px 10px;
    margin-top: 7px;
    & > p {
      color: #4a4a4a;
      font-size: 14px;
      margin: 0;
    }
  }
  ${props => props.css}
`;

export default DropdownSelector;
