import React from "react";
import styled from "styled-components";
import * as Catalog from "../Contexts/CatalogContext";
import * as Cart from "../Contexts/CartContext";
import ProductConfig from "./ProductConfig";
import * as Icon from "./Icon";
import * as Ant from "antd";
import UploadButton from "./UploadFileButton";
import Constants from "../constants";
import RefundDialog from "./RefundDialog";
import ReturnAppDialog from "./ReturnApplicationDialog";
import * as Theme from "../Theme";

class CartItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {} = this.state;
    let { viewOnly, product, item, itemIndex } = this.props;

    return (
      <Wrapper>
        <Col basis={30}>
          <TH>#</TH>
          <TD>{itemIndex + 1}</TD>
        </Col>

        <Col basis={150} flex={1}>
          <TH>課程</TH>
          <TD>{product.name}</TD>
        </Col>

        <Col flex={1}>
          <TH>課程資訊</TH>
          <TD>
            <div>{product.description}</div>
          </TD>
        </Col>

        <Col basis={80}>
          <TH>價格</TH>
          <TD>{item.price}</TD>
        </Col>

        {!viewOnly && (
          <Col basis={40} css="border: 0">
            <TH>刪除</TH>
            <TD>
              <div onClick={() => this._removeItem(itemIndex)}>
                <Icon.Close size={18} color="black" />
              </div>
            </TD>
          </Col>
        )}
      </Wrapper>
    );
  }

  _removeItem = async index => {
    Cart.Actions.removeItem(index);
  };
}

const TH = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  border-bottom: solid 1px #bbb;
`;

const TD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  border: 1px solid #eee;
  border-top: 2px solid ${Theme.colors.main};
  border-radius: 5px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  flex: ${props => props.flex || 0};
  flex-basis: ${props => props.basis + "px" || "auto"};

  ${props => props.css}
`;

const TextArea = styled.textarea`
  padding: 10px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid #a6a6a6;

  :focus {
    outline: none;
  }
`;

export default CartItem;
